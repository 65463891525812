import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { createStore, combineReducers } from 'redux';
import UIReducer from './store/reducers/UIReducer';
import ModalReducer from './store/reducers/ModalReducer';
import { Provider } from 'react-redux';
import ConnectedIntlProvider from './containers/Wrappers/ConnectedIntlProvider'

const rootReducer = combineReducers({
    ui_red: UIReducer,
    modal: ModalReducer
});

const store = createStore(rootReducer);

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <ConnectedIntlProvider>
                <App />
            </ConnectedIntlProvider>
        </BrowserRouter>
    </Provider>
);

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(app, rootElement);
} else {
    ReactDOM.render(app, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();