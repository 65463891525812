import React, { Component } from 'react';
import Aux from '../../../hoc/Aux_';
import { FormattedHTMLMessage } from 'react-intl';

class Faq extends Component {

    render() {
        return (
            <Aux>
                <section className="section bg-light" id="faq">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="text-center">
                                    <h2 className="font-weight-bold pt-2">
                                        <FormattedHTMLMessage id="app.FaqMainHeader">
                                            Frequently Asked Questions
                                        </FormattedHTMLMessage>
                                    </h2>
                                    <p className=" font-weight-light pt-1">
                                        <FormattedHTMLMessage id="app.FaqMainParagraph">
                                            Questions and answers on the product, the technology and the deployment. Contact us for any further doubts!
                                        </FormattedHTMLMessage>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3 ml-2">
                            <div className="col-lg-6 faq-side">
                                <div className="faq pt-5">
                                    <p className="text-uppercase user-title mb-1">
                                        <FormattedHTMLMessage id="app.FaqHeaderProduct">
                                            The Product
                                        </FormattedHTMLMessage>
                                    </p>
                                    <div className="faq-ans">
                                        <h3 className="faq-title"><i className="mdi mdi-help-circle"></i>
                                            <FormattedHTMLMessage id="app.FaqHeaderQuestion1">
                                                What is the difference between Di-Dy and other competitors?
                                            </FormattedHTMLMessage>
                                        </h3>
                                        <p className="faq-sub-title text-muted pt-2">
                                            <FormattedHTMLMessage id="app.FaqHeaderAnswer1">
                                                Di-Dy has the ultimate target to help with decision making taking into account all the relevant data. Therefore it does not have a vision limited to specific areas, but a broader scope. It reaches a global vision.
                                            </FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                                <div className="faq pt-4">
                                    <p className="text-uppercase user-title mb-1">
                                        <FormattedHTMLMessage id="app.FaqHeaderProduct">
                                            The Product
                                        </FormattedHTMLMessage>
                                    </p>
                                    <div className="faq-ans">
                                        <h3 className="faq-title"><i className="mdi mdi-help-circle"></i>
                                            <FormattedHTMLMessage id="app.FaqHeaderQuestion2">
                                                How do you handle employee privacy and data security?
                                            </FormattedHTMLMessage>
                                        </h3>
                                        <p className="faq-sub-title text-muted pt-2">
                                            <FormattedHTMLMessage id="app.FaqHeaderAnswer2">
                                                Protecting the employee's privacy is part of Di-Dy’s DNA. Data is stored anonymously, without personal information, and the visualization and reports are aggregated. Di-Dy strictly complies with the GDPR regulations. In addition, we recommend to inform all employees explicitly of their data treatment.
                                            </FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                                <div className="faq pt-4">
                                    <p className="text-uppercase user-title mb-1">
                                        <FormattedHTMLMessage id="app.FaqHeaderProduct">
                                            The Product
                                        </FormattedHTMLMessage>
                                    </p>
                                    <div className="faq-ans">
                                        <h3 className="faq-title"><i className="mdi mdi-help-circle"></i>
                                            <FormattedHTMLMessage id="app.FaqHeaderQuestion3">
                                                Can I build my own reports?
                                            </FormattedHTMLMessage>
                                        </h3>
                                        <p className="faq-sub-title text-muted pt-2">
                                            <FormattedHTMLMessage id="app.FaqHeaderAnswer3">
                                                Di-Dy’s objective is to help the customer create reports that solve their problems. New reports and specific information can be designed together with the client.
                                            </FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 faq-side ">
                                <div className="faq pt-5">
                                    <p className="text-uppercase user-title mb-1">
                                        <FormattedHTMLMessage id="app.FaqHeaderTechnology">
                                            The Technology
                                        </FormattedHTMLMessage>
                                    </p>
                                    <div className="faq-ans">
                                        <h3 className="faq-title"><i className="mdi mdi-help-circle"></i>
                                            <FormattedHTMLMessage id="app.FaqHeaderQuestion4">
                                                What is the installation time?
                                            </FormattedHTMLMessage>
                                        </h3>
                                        <p className="faq-sub-title text-muted pt-2">
                                            <FormattedHTMLMessage id="app.FaqHeaderAnswer4">
                                                We will design together a delivery roadmap, it is not automatic but as soon as we integrate your data in 2-4 weeks, Di-Dy will be up and running and delivering periodically new data without any additional effort.
                                            </FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                                <div className="faq pt-4">
                                    <p className="text-uppercase user-title mb-1">
                                        <FormattedHTMLMessage id="app.FaqHeaderTechnology">
                                            The Technology
                                    </FormattedHTMLMessage>
                                    </p>
                                    <div className="faq-ans">
                                        <h3 className="faq-title"><i className="mdi mdi-help-circle"></i>
                                            <FormattedHTMLMessage id="app.FaqHeaderQuestion5">
                                                Do the organization or the employees need to install something special?
                                            </FormattedHTMLMessage>
                                        </h3>
                                        <p className="faq-sub-title text-muted pt-2">
                                            <FormattedHTMLMessage id="app.FaqHeaderAnswer5">
                                                Di-Dy is a seamless big-data integrator. It is built to give an assessment based on your current systems. Depending on the IT maturity of your company, it will be more or less advanced. If you require additional features we will assess you on how to improve your systems for a more data-driven experience.
                                            </FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                                <div className="faq pt-4">
                                    <p className="text-uppercase user-title mb-1">
                                        <FormattedHTMLMessage id="app.FaqHeaderTechnology">
                                            The Technology
                                    </FormattedHTMLMessage>
                                    </p>
                                    <div className="faq-ans">
                                        <h3 className="faq-title"><i className="mdi mdi-help-circle"></i>
                                            <FormattedHTMLMessage id="app.FaqHeaderQuestion6">
                                                What is the price? How much does the service cost?
                                            </FormattedHTMLMessage>
                                        </h3>
                                        <p className="faq-sub-title text-muted pt-2">
                                            <FormattedHTMLMessage id="app.FaqHeaderAnswer6">
                                                The pricing counts on an installation fee and then a user monthly/yearly fee per service but in the early stages we are open to a project base fee. Contact us and we will study the best solution for your business.
                                            </FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Aux>
        );
    }
}

export default Faq;
