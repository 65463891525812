import * as actionTypes from '../action';

const initialState = {
    topbar: true,
    loginpage: false,
    islight: false,
    language: 'en'
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TOPBAR:
            return {
                ...state,
                topbar: !state.topbar
            };

        case actionTypes.LOGINPAGE:
            return {
                ...state,
                loginpage: !state.loginpage
            };

        case actionTypes.ISLIGHT:
            return {
                ...state,
                islight: !state.islight
            };

        case actionTypes.LANGUAGE:
            return {
                ...state,
                language: action.value
            };

        default:
            return state;
    }
}

export default reducer;