import React, { Component } from 'react';
import Aux from '../../../hoc/Aux_';
import { FormattedHTMLMessage } from 'react-intl';

class Pricing extends Component {
    render() {
        return (
            <Aux>
                <section className="section bg-light" id="solution">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <h2 className="font-weight-bold pt-2">
                                        <FormattedHTMLMessage id="app.PricingMainHeader">What are the analytics behind Di-Dy technologyS</FormattedHTMLMessage>
                                    </h2>
                                    <p className=" font-weight-light pt-3">
                                        <FormattedHTMLMessage id="app.PricingMainParagraph1">texto superculo</FormattedHTMLMessage>
                                    </p>
                                    <p className=" font-weight-light pt-1">
                                        <FormattedHTMLMessage id="app.PricingMainParagraph2">texto superculo</FormattedHTMLMessage>
                                    </p>
                                    <p className=" font-weight-light pt-1">
                                        <FormattedHTMLMessage id="app.PricingMainParagraph3">texto superculo</FormattedHTMLMessage>
                                    </p>
                                    <div className="mb-didy bg-black mb-4">
                                        <img src="images/graphsGroup.png" alt="" className="img-fluid center-block home-dashboard mb-5" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section bg-dark" id="solution">
                    <div className="container">
                        <h3 className="pt-2 mb-4 section-title font-weight-bold display-4 text-white text-center">
                            <FormattedHTMLMessage id="app.PricingHeaderMachine">Where does the data come from?</FormattedHTMLMessage>
                        </h3>
                        <p className="pt-3col-md-10 section-subtitle text-white text-center px-5">
                            <FormattedHTMLMessage id="app.PricingParagraphMachine">Our machine learning engine collects the data from a big number of data source through our connectors, Di-Dy processes the data through our algorithms to build the most advanced people & workplace intelligence.</FormattedHTMLMessage>
                        </p>
                        <div className="row m-3 pt-5">
                            <img className="w-100 h-100 mx-auto d-block" src="images/connectors-didy.png" alt="Di-Dy connectors" />
                        </div>
                    </div>
                </section>
            </Aux>
        );
    }
}

export default Pricing;