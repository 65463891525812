import React, { Component } from 'react';
import Services from '../Sections/Services';
import DigitalDynamics from '../Sections/Features';
import Pricing from '../Sections/Pricing';
import Faq from '../Sections/Faq';
// import { Link } from 'react-router-dom';
import Aux from '../../../hoc/Aux_';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/action';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import { FormattedHTMLMessage } from 'react-intl';


class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };
        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    componentDidMount() {
        if (this.props.islight === false) {
            this.props.UpdateLight();
        }
    }

    render() {
        return (
            <Aux>
                <section className="home-bg-color" id="home">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 text-center text-black">
                                <div className="text-center">
                                    <h1 className="mt-3 pt-5 home-title font-weight-bold">
                                        <FormattedHTMLMessage id="app.homeMainHeaderPart1">WORKPLACE & PEOPLE ANALYTICS</FormattedHTMLMessage>
                                        <br></br>
                                        <FormattedHTMLMessage id="app.homeMainHeaderPart2">WITH REAL IMPACT </FormattedHTMLMessage>
                                    </h1>
                                </div>
                                <div className="container mt-4">

                                    <div className="mt-5" role="toolbar" aria-label="Toolbar with button groups">
                                        <div className="btn-group mr-md-4- mr-sm-0 mr-xs-0" role="group" aria-label="First group">
                                            <button onClick={this.openModal} type="button" className="btn btn-light border rounded-0">
                                                <FormattedHTMLMessage id="app.homeMainButtonVideo">
                                                    WATCH VIDEO
                                                </FormattedHTMLMessage>
                                            </button>
                                        </div>
                                        <div className="btn-group ml-md-4 ml-sm-0 ml-xs-0 mt-xl-0 mt-lg-0 mt-md-0 space-btn" role="group" aria-label="Second group">
                                            <a href="#contact" className="btn backg-green text-white rounded-0">
                                                <FormattedHTMLMessage id="app.homeMainButtonDemo">
                                                    BOOK A DEMO
                                                </FormattedHTMLMessage></a>
                                        </div>
                                    </div>
                                </div>
                                <ModalVideo channel='vimeo' videoId='357863426' isOpen={this.state.isOpen} onClose={() => this.setState({ isOpen: false })} />
                                <div className="">
                                    <img src="images/gif_didy_sinbarra.gif" alt="" className="img-fluid center-block home-dashboard" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <DigitalDynamics />
                <Services />
                <Pricing />
                <Faq />
            </Aux>
        );
    }
}

const mapStatetoProps = state => {
    return {
        islight: state.ui_red.islight
    };
}

const mapDispatchtoProps = dispatch => {
    return {
        UpdateLight: () => dispatch({ type: actionTypes.ISLIGHT, value: true })
    };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Home);
