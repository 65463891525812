import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { MODAL_OPEN, MODAL_CONTENT } from '../../../store/action';
import TermsAndConditions from './TermsAndConditions';

class CookieBanner extends Component {
    constructor(props) {
        super(props);
        this.onLearnMore = this.onLearnMore.bind(this);
    }

    onLearnMore(event) {
        let content = {
            type: MODAL_CONTENT,
            value: <TermsAndConditions></TermsAndConditions>
        };
        let open = {
            type: MODAL_OPEN
        };
        this.props.dispatch(content);
        this.props.dispatch(open);
    }

    render() {
        window.AddCookieBanner(
            this.props.intl.formatMessage({ 'id': 'cookie.message' }),
            this.props.intl.formatMessage({ 'id': 'cookie.dismiss' }),
            this.props.intl.formatMessage({ 'id': 'cookie.deny' }),
            this.props.intl.formatMessage({ 'id': 'cookie.allow' }),
            this.props.intl.formatMessage({ 'id': 'cookie.link' }),
            this.props.intl.formatMessage({ 'id': 'cookie.policy' })
        );
        return <button onClick={this.onLearnMore} id='terms-conditions-btn' style={{ 'display': 'none' }}></button>;
    };
}

const mapStatetoProps = state => ({});

const mapDispatchtoProps = dispatch => ({
    dispatch: (action) => dispatch(action)
});

export default injectIntl(connect(mapStatetoProps, mapDispatchtoProps)(CookieBanner));
