import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from '../../hoc/Aux_';
import axios from 'axios';
//import { injectIntl } from 'react-intl';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { MODAL_OPEN, MODAL_CONTENT } from '../../store/action';
import TermsAndConditions from '../UI/Legal/TermsAndConditions';
import Recaptcha from 'react-google-recaptcha';

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      lastname: '',
      organization: '',
      email: '',
      jobTitle: '',
      industry: '',
      phone: '',
      email_err: '',
      name_err: '',
      organization_err: '',
      lastname_err: '',
      jobTitle_err: '',
      industry_err: '',
      phone_err: '',
      return_msg: '',
      flag: false,
      agree: false,
      //Recaptcha
      isVerify: false,
      recaptcha: '',
    }
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeLastName = this.handleChangeLastName.bind(this);
    this.handleChangeOrganization = this.handleChangeOrganization.bind(this);
    this.handleChangeJobTitle = this.handleChangeJobTitle.bind(this);
    this.handleChangeIndustry = this.handleChangeIndustry.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChangeAgree = this.handleChangeAgree.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onLearnMore = this.onLearnMore.bind(this);

    //this.handleSubscribe =this.handleSuscript.bind(this);
    //this.recaptchaLoaded =this.recapchaLoaded.bind(this);
    //this.verifyCallback=this.verifyCallback.bind(this);

  }

  onLearnMore(event) {
    let content = {
      type: MODAL_CONTENT,
      value: <TermsAndConditions></TermsAndConditions>
    };
    let open = {
      type: MODAL_OPEN
    };
    this.props.dispatch(content);
    this.props.dispatch(open);
  }

  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
    // eslint-disable-next-line
    var EmailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (e.target.value === '')
      this.setState({ email_err: this.props.intl.formatMessage({ id: 'app.FooterRequired' }) });
    else if (EmailReg.test(e.target.value))
      this.setState({ email_err: '' });
    else
      this.setState({ email_err: 'Enter Valid Email' });
  }

  handleChangeName(e) {
    this.setState({ name: e.target.value });
    if (e.target.value === '')
      this.setState({ name_err: this.props.intl.formatMessage({ id: 'app.FooterRequired' }) });
    else
      this.setState({ name_err: '' });
  }

  handleChangeLastName(e) {
    this.setState({ lastname: e.target.value });
    if (e.target.value === '')
      this.setState({ lastname_err: this.props.intl.formatMessage({ id: 'app.FooterRequired' }) });
    else
      this.setState({ lastname_err: '' });
  }

  handleChangeOrganization(e) {
    this.setState({ organization: e.target.value });
    if (e.target.value === '')
      this.setState({ organization_err: this.props.intl.formatMessage({ id: 'app.FooterRequired' }) });
    else
      this.setState({ organization_err: '' });
  }

  handleChangeJobTitle(e) {
    this.setState({ jobTitle: e.target.value });
    if (e.target.value === '')
      this.setState({ jobTitle_err: this.props.intl.formatMessage({ id: 'app.FooterRequired' }) });
    else
      this.setState({ jobTitle_err: '' });
  }

  handleChangeIndustry(e) {
    this.setState({ industry: e.target.value });
    if (e.target.value === '')
      this.setState({ industry_err: this.props.intl.formatMessage({ id: 'app.FooterRequired' }) });
    else
      this.setState({ industry_err: '' });
  }

  handleChangePhone(e) {
    this.setState({ phone: e.target.value });
    if (e.target.value === '')
      this.setState({ phone_err: this.props.intl.formatMessage({ id: 'app.FooterRequired' }) });
    else
      this.setState({ phone_err: '' });
  }

  handleChangeAgree(e) {
    this.setState({ agree: e.target.checked });
  }

  handleSubmit() {
    if (this.state.name === '')
      this.setState({ name_err: this.props.intl.formatMessage({ id: 'app.FooterRequired' }) });
    if (this.state.email === '')
      this.setState({ email_err: this.props.intl.formatMessage({ id: 'app.FooterRequired' }) });
    if (this.state.lastname === '')
      this.setState({ lastname_err: this.props.intl.formatMessage({ id: 'app.FooterRequired' }) });
    if (this.state.organization === '')
      this.setState({ organization_err: this.props.intl.formatMessage({ id: 'app.FooterRequired' }) });
    if (this.state.jobTitle === '')
      this.setState({ jobTitle_err: this.props.intl.formatMessage({ id: 'app.FooterRequired' }) });
    if (this.state.industry === '')
      this.setState({ industry_err: this.props.intl.formatMessage({ id: 'app.FooterRequired' }) });
    if (this.state.phone === '')
      this.setState({ phone_err: this.props.intl.formatMessage({ id: 'app.FooterRequired' }) });
    if (
      this.state.name === '' || this.state.email === '' ||
      this.state.lastname === '' || this.state.organization === '' ||
      this.state.industry === '' || this.state.jobTitle === '' ||
      this.state.phone === '') {
      this.setState({ return_msg: this.props.intl.formatMessage({ id: 'app.FooterMustFullfillRequired' }), flag: true });
    }
    else if (this.state.agree === false) {
      this.setState({ return_msg: this.props.intl.formatMessage({ id: 'app.FooterMustAgreeTerms' }), flag: true });
    }
    else if (this.state.isVerify === false) {
      this.setState({ return_msg: this.props.intl.formatMessage({ id: 'app.FooterMustCheckRobot' }), flag: true });
    }
    else {
      this.submitData = {
        name: this.state.name,
        lastname: this.state.lastname,
        organization: this.state.organization,
        email: this.state.email,
        jobTitle: this.state.jobTitle,
        industry: this.state.industry,
        phone: this.state.phone,
        agreeTerms: this.state.agree,
        ticketRecaptcha: this.state.recaptcha, //Ticket del captcha
      }
      console.info("respuesta ->", this.state.recaptcha)
      axios.post(process.env.REACT_APP_BACKEND_URL + '/api/user/contact', this.submitData)
        .then(doc => {
          this.setState({
            return_msg: 'Success.', flag: true,
            name: '',
            lastname: '',
            organization: '',
            email: '',
            jobTitle: '',
            industry: '',
            phone: '',
            agree: false,
            isVerify: false,
            recaptcha: ''
          });
          setTimeout(() => {
            this.setState({ flag: false })
          }, 3000);
        })
        .catch(err => {
          this.setState({ return_msg: 'There was an error submitting form data', flag: true });
          setTimeout(() => {
            this.setState({ flag: false })
          }, 3000);
        })
    }
  }

  recaptchaError = () => { //verificacion/error del componente ReCaptcha
    alert("error de captcha")
  }

  verifyCallback = (response) => {
    this.setState({ recaptcha: response, isVerify: true })
  }

  render() {
    return (
      <Aux>
        <section className="pt-5 bg-dark" id="contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="text-center">
                  <h3 className="section-title font-weight-bold text-white pt-5">
                    <FormattedHTMLMessage id="app.FooterMainHeader">
                      Contact us
                    </FormattedHTMLMessage>
                  </h3>
                  <p className="section-subtitle pt-3 text-whte">
                    <FormattedHTMLMessage id="app.FooterMainParagraph1">
                      Ready to know the big data potential of your organization?
                    </FormattedHTMLMessage>
                    <br></br>
                    <FormattedHTMLMessage id="app.FooterMainParagraph2">
                      Request your free demo!
                    </FormattedHTMLMessage>
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-5">
              <div className="col-lg-8 bg-white p-md-5 p-sm-0 p-xs-0 mt-md-4 rounded">
                <div className="custom-form">
                  <div id="message">
                    {this.state.flag ?
                      <div className="alert alert-success">
                        <strong>{this.state.return_msg}</strong>
                      </div>
                      : null}
                  </div>
                  <form name="contact-form" id="contact-form">
                    <div className="row ml-md-3 mr-md-3 m-0">
                      <div className="col-lg-6 mt-3">
                        <input type="text" id="name" value={this.state.name}
                          onChange={this.handleChangeName} className="form-control"
                          placeholder={this.props.intl.formatMessage({ "id": "app.FooterFormPlaceholderName" })} />
                        <span id="err">{this.state.name_err}</span>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <input type="text" id="lastname" value={this.state.lastname}
                          onChange={this.handleChangeLastName} className="form-control"
                          placeholder={this.props.intl.formatMessage({ "id": "app.FooterFormPlaceholderLastName" })} />
                        <span id="err">{this.state.lastname_err}</span>
                      </div>
                    </div>

                    <div className="row ml-md-3 mr-md-3 m-0">
                      <div className="col-lg-6 mt-3">
                        <input type="text" id="email" className="form-control"
                          value={this.state.email} onChange={this.handleChangeEmail}
                          placeholder={this.props.intl.formatMessage({ "id": "app.FooterFormPlaceholderEmail" })} />
                        <span id="err">{this.state.email_err}</span>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <input type="text" id="jobTitle" value={this.state.jobTitle}
                          onChange={this.handleChangeJobTitle} className="form-control"
                          placeholder={this.props.intl.formatMessage({ "id": "app.FooterFormPlaceholderJob" })} />
                        <span id="err">{this.state.jobTitle_err}</span>
                      </div>
                    </div>

                    <div className="row ml-md-3 mr-md-3 m-0">
                      <div className="col-lg-6 mt-3">
                        <input type="text" id="organization" value={this.state.organization}
                          onChange={this.handleChangeOrganization} className="form-control"
                          placeholder={this.props.intl.formatMessage({ "id": "app.FooterFormPlaceholderOrganization" })} />
                        <span id="err">{this.state.organization_err}</span>
                      </div>
                      <div className="col-lg-6 mt-3">
                        <input type="text" id="industry" className="form-control"
                          value={this.state.industry} onChange={this.handleChangeIndustry}
                          placeholder={this.props.intl.formatMessage({ "id": "app.FooterFormPlaceholderIndustry" })} />
                        <span id="err">{this.state.industry_err}</span>
                      </div>
                    </div>

                    <div className="row ml-md-3 mr-md-3 m-0">
                      <div className="col-lg-6 mt-3">
                        <input type="text" id="phone" value={this.state.phone}
                          onChange={this.handleChangePhone} className="form-control"
                          placeholder={this.props.intl.formatMessage({ "id": "app.FooterFormPlaceholderPhone" })} />
                        <span id="err">{this.state.phone_err}</span>
                      </div>
                    </div>

                    <div className="mt-4 text-center">
                      <input type="button" id="submit" name="send" onClick={this.handleSubmit} className="submitBnt btn btn-custom" value={this.props.intl.formatMessage({ "id": "app.FooterFormSubmit" })} />
                      <div id="simple-msg"></div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-md-12">
                        <p className="text-center submit-terms mb-0 mt-3">
                          <input
                            name="agree"
                            type="checkbox"
                            checked={this.state.agree}
                            onChange={this.handleChangeAgree} />&nbsp;
                          <FormattedHTMLMessage id="app.FooterAgree"></FormattedHTMLMessage>*: &nbsp;
                          <FormattedHTMLMessage id="terms.message"></FormattedHTMLMessage>&nbsp;
                          <span className="cc-link" onClick={this.onLearnMore}>
                            <FormattedHTMLMessage id="terms.link"></FormattedHTMLMessage>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="recaptcha row justify-content-center mt-5">
                      <Recaptcha
                        sitekey="6Ld9CLcUAAAAAByOmot0MJLRgQ1KMs9goPmmNiMB"
                        render="explicit"
                        theme="light"
                        onChange={this.verifyCallback}
                        onErrored={this.recaptchaError}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row mt-5 pt-5 pb-2">
              <div className="col-md-12">
                <div className="text-white footer-alt">
                  <div className="float-left">
                    <p className="pb-0">2019 © Di-Dy</p>
                  </div>
                  <div className="float-right">
                    <ul className="list-inline social pb-0">
                      <li className="list-inline-item pl-2">
                        <a href="mailto:di-dy@di-dy.com" className="text-white">
                          di-dy@di-dy.com
                        </a>
                      </li>
                      <li className="list-inline-item pl-2">
                        <a href="https://www.linkedin.com/company/di-dy/about/" className="text-white">
                          <i className="mdi mdi-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Aux>
    );
  }
}

const mapStatetoProps = state => ({});

const mapDispatchtoProps = dispatch => ({
  dispatch: (action) => dispatch(action)
});

export default injectIntl(connect(mapStatetoProps, mapDispatchtoProps)(Footer));
