import * as actionTypes from '../action';

const initialState = {
    isOpen: false,
    content: '',
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MODAL_OPEN:
            return {
                ...state,
                isOpen: true
            };

        case actionTypes.MODAL_CLOSE:
            return {
                ...state,
                isOpen: false
            };

        case actionTypes.MODAL_CONTENT:
            return {
                ...state,
                content: action.value
            };

        default:
            return state;
    }
}

export default reducer;