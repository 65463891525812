import React, { Component } from 'react';
import Aux from '../../hoc/Aux_';
import { Link } from 'react-router-dom';
import * as actionTypes from '../../store/action';
import { connect } from 'react-redux';
import $ from 'jquery';
import { FormattedHTMLMessage } from 'react-intl';

class HeaderLight extends Component {

    componentDidMount() {
        $('.navbar-toggle').on('click', function (event) {
            $(this).toggleClass('open');
            $('#navigation').slideToggle(400);
        });

        $('.navigation-menu>li').slice(-2).addClass('last-elements');

        $('.language-menu,.menu-arrow,.submenu-arrow').on('click', function (e) {
            if ($(window).width() < 992) {
                e.preventDefault();
                $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
            }
        });
    }


    render() {
        return (
            <Aux>
                <header id="topnav" className="defaultscroll topnav-light sticky fixed-top">
                    <div className="container">
                        <div className="navigation-menu ">
                            <a className="logo" href="#home">
                                <img src="images/DiDyColor.png" width="90" height="40" alt="logoDidy" />
                            </a>
                        </div>
                        <div className="menu-extras">
                            <div className="menu-item">
                                <Link to="#" className="navbar-toggle">
                                    <div className="lines">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div id="navigation">
                            <ul className="navigation-menu ">

                                <li className="has-submenu ">
                                    <a href="#digitaldynamics"><FormattedHTMLMessage id="app.headerLink1">DIGITAL DYNAMICS</FormattedHTMLMessage></a>
                                </li>

                                <li className="has-submenu">
                                    <a href="#features"><FormattedHTMLMessage id="app.headerLink2">FEATURES</FormattedHTMLMessage></a>
                                </li>

                                <li className="has-submenu">
                                    <a href="#solution"><FormattedHTMLMessage id="app.headerLink3">SOLUTION</FormattedHTMLMessage></a>
                                </li>

                                <li className="has-submenu">
                                    <a href="#faq"><FormattedHTMLMessage id="app.headerLink4">FAQs</FormattedHTMLMessage></a>
                                </li>
                            </ul>
                            <ul className="navigation-menu menu-right mt-2">
                                <li className="has-submenu">
                                    <a href="#contact">
                                        <span className="border border-secondary rounded booked"><FormattedHTMLMessage id="app.headerLinkDemo">BOOK A DEMO</FormattedHTMLMessage></span>
                                    </a>
                                </li>
                                <li className="has-submenu">
                                    <a href="#home" className="has-submenu language-menu"><FormattedHTMLMessage id="app.headerLanguage">Language</FormattedHTMLMessage></a>
                                    <span className="menu-arrow"></span>
                                    <ul className="submenu">
                                        <li>
                                            <button value="es" onClick={() => this.props.selectLanguage('es')} className="my-1 btn-block bg-transparent border border-white"> ES</button>
                                        </li>
                                        <li>
                                            <button value="en" onClick={() => this.props.selectLanguage('en')} className="my-1 btn-block bg-transparent border border-white"> EN</button>
                                        </li>
                                        <li>
                                            <button value="de" onClick={() => this.props.selectLanguage('de')} className="my-1 btn-block bg-transparent border border-white"> DE</button>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </header>
            </Aux>
        );
    }
}

// #topnav .navigation-menu {
//     float: none;
//     max-height: 400px;
// }

// #topnav .navigation-menu {
//     float: left;
//     list-style: none;
//     margin: 0;
//     padding: 0;
// }

const mapStatetoProps = state => {
    return {
        lng: state.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        selectLanguage: (language) => {
            dispatch({ type: actionTypes.LANGUAGE, value: language })
        }
    }
}

export default connect(mapStatetoProps, mapDispatchToProps)(HeaderLight)
