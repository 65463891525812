import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

class TermsAndConditions extends Component {
    render() {
        return <div>
            <div>
                <h1>Terms and Conditions</h1>
                <p>This website belongs to IVIMM LOSO S.L. and in compliance with the provisions of Article 10 of Law 34/2002 of 11 July on information society services and electronic commerce, the LSSI makes available to users the following general information:</p>
                <p>IVIMM LOSO S.L. is a Limited Company, registered in the Mercantile Registry of Madrid, Volume 36767, Folio 143, Page M-2658722.</p>
                <p>IVIMM LOSO S.L. uses the domain www.di-dy.com to provide its services in the Information Society.</p>
                <p>Its identifying data are:</p>
                <ul>
                    <li>CIF: B87987434</li>
                    <li>Address: Marqués de Lema 7, 28003 Madrid</li>
                </ul>
                <p>To communicate with us directly and effectively you can go to the email address di-dy@di-dy.com</p>
                <h3>1- ACCESS TO THE WEBSITE</h3>
                <p>The conditions governing access to and use of this website are those specified below. The user is recommended to read them before browsing and in order to know the terms that govern access and use of this portal. Free access is allowed to all the information contained in the website.</p>
                <h3>2- MODIFICATIONS TO THE PAGE AND INTERRUPTIONS OR ERRORS IN ACCESS</h3>
                <p>We reserve the right to make, at any time and without prior notice, modifications and updates of the information contained on the website, its configuration and presentation and the conditions of access. Similarly, we do not guarantee in any case the availability and continuity of the operation of the website and services, which may suffer interruptions for reasons beyond the company's control. As far as possible, any type of interruption in the operation of the website will be notified in advance, but we are exempt from any responsibility for damages that the lack of availability of the website may cause to its users or clients.</p>
                <h3>3- PROTECTION OF PERSONAL DATA</h3>
                <p>The personal data collected through this website are processed in accordance with the requirements of the RGPD and the LOPD. The user is informed that when requesting more information by entering your email or phone number on our website, your personal data will be processed by IVVIM LOSO S.L. in order to respond to the query or request that in the mail transports us, as well as to monitor it. Your data will be treated on the basis of your request and will be kept for the time necessary to meet your request. You will be able to exercise your rights of access, rectification, suppression, limitation of the treatment and opposition by means of writing, to the one that will have to accompany of copy of your DNI, directed to the direction above mentioned. The holder who considers that his personal data have been treated incorrectly has the right to lodge a complaint with the Spanish Data Protection Agency: www.aepd.es</p>
                <h3>4- CLAUSE OF COPYRIGHT AND INDUSTRIAL PROPERTY RIGHTS</h3>
                <p>IVVIM LOSO S.L. is the exclusive owner of the intellectual and industrial property rights relating to this website and all its content, whether images, audiovisual products, texts or data. Any act of reproduction, distribution, public communication or transformation must be done with the prior written consent of those responsible for the company. All this material is protected by intellectual and industrial property legislation and its improper use may be subject to sanctions. The consultation or downloading of the contents of the page does not imply the cession of any intellectual or industrial property right over them.</p>
                <h3>5- USER TRACKING TECHNOLOGIES AND USE OF COOKIES</h3>
                <p>In this web site cookies are used during navigation for the sole purpose of facilitating the transit through the portal and without IVVIM LOSO S.L. store and store personal information of users for other purposes.</p>
                <h3>6- VALIDITY OF THE CONDITIONS OF USE</h3>
                <p>Partially, publishing any change in the same way that these general conditions appear or through any type of communication addressed to users. For this reason, the validity of these conditions will be the time of their exposure on the website and until they are modified in whole or in part, at which time the modified conditions will come into force. In this sense, it is understood that the registered user who continues to use the services of the portal after the modification of the conditions, accepts them and is subject to them. The conditions of use will always be available to the user indicating the date of last update.</p>
            </div>
        </div>
    };
}

export default injectIntl(TermsAndConditions);
