import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import messages_es from "../../translations/es.json";
import messages_de from "../../translations/de.json";
import messages_en from "../../translations/en.json";

const messages = {
  'es': messages_es,
  'de': messages_de,
  'en': messages_en
};

// This function will map the current redux state to the props for the component that it is "connected" to.
// When the state of the redux store changes, this function will be called, if the props that come out of
// this function are different, then the component that is wrapped is re-rendered.
function mapStateToProps(state) {
  const lang = state.ui_red.language;
  return { locale: lang, messages: messages[lang] };
}

export default connect(mapStateToProps)(IntlProvider);