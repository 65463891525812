import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../MainContent/Pages/HomeNine';

// import HomeOne from '../MainContent/Pages/HomeOne';
// import HomeTwo from '../MainContent/Pages/HomeTwo';
// import HomeThree from '../MainContent/Pages/HomeThree';
// import HomeFour from '../MainContent/Pages/HomeFour';
// import HomeFive from '../MainContent/Pages/HomeFive';
// import HomeSix from '../MainContent/Pages/HomeSix';
// import HomeSeven from '../MainContent/Pages/HomeSeven';
// import HomeEight from '../MainContent/Pages/HomeEight';
// import HomeTen from '../MainContent/Pages/HomeTen';
// import Login from '../MainContent/Pages/Login';
// import PasswordForget from '../MainContent/Pages/PasswordForget';
// import SignUp from '../MainContent/Pages/SignUp';

class mainbuilder extends Component {
    render() {
        return (
            <Switch>

                <Route exact path="/" component={Home} />

                {/* <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
                    <Route path={`${process.env.PUBLIC_URL}/password_forget`} component={PasswordForget} />
                    <Route path={`${process.env.PUBLIC_URL}/signup`} component={SignUp} />  */}

            </Switch>
        );
    }
}

export default mainbuilder;