import React, { Component } from 'react';
import Aux from '../../../hoc/Aux_';
import { FormattedHTMLMessage } from 'react-intl';

class Services extends Component {
    render() {
        return (
            <Aux>
                <section className="section" id="features">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="text-center">
                                    <h2 className="font-weight-bold pt-2">
                                        <FormattedHTMLMessage id="app.ServicesMainHeader">An all-in-ONE platform</FormattedHTMLMessage>
                                    </h2>
                                    <p className=" font-weight-light pt-1">
                                        <FormattedHTMLMessage id="app.ServicesMainParagraph">Di-Dy works as independent modules that you can acquire independently.</FormattedHTMLMessage>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-3">
                                <div className="text-center p-4 mt-3">
                                    <div className="service-icon">
                                        <i className="mdi-didy mdi-didy-work-dynamics text-custom-Purple"></i>
                                    </div>
                                    <div className="service-desc pt-4">
                                        <h5>
                                            <FormattedHTMLMessage id="app.ServicesCardTitle1Part1">WORK</FormattedHTMLMessage>
                                            <br></br>
                                            <FormattedHTMLMessage id="app.ServicesCardTitle1Part2">DYNAMICS</FormattedHTMLMessage>
                                        </h5>
                                        <p className="pt-2 text-muted">
                                            <FormattedHTMLMessage id="app.ServicesCardText1">Understand the different user profiles of your organisation and the way they work.</FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center p-4 mt-3">
                                    <div className="service-icon">
                                        <i className="mdi-didy mdi-didy-communication text-custom-Green"></i>
                                    </div>
                                    <div className="service-desc pt-4">
                                        <h5>
                                            <FormattedHTMLMessage id="app.ServicesCardTitle2Part1">COMMUNICATION</FormattedHTMLMessage>
                                            <br></br>
                                            <FormattedHTMLMessage id="app.ServicesCardTitle2Part2">RELATIONSHIPS</FormattedHTMLMessage>
                                        </h5>
                                        <p className="pt-2 text-muted">
                                            <FormattedHTMLMessage id="app.ServicesCardText2">Learn about your communication network and identify silos.</FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center p-4 mt-3">
                                    <div className="service-icon">
                                        <i className="mdi-didy mdi-didy-space-occupancy text-custom-Orange"></i>
                                    </div>
                                    <div className="service-desc pt-4">
                                        <h5>
                                            <FormattedHTMLMessage id="app.ServicesCardTitle3Part1">SPACE</FormattedHTMLMessage>
                                            <br></br>
                                            <FormattedHTMLMessage id="app.ServicesCardTitle3Part2">OCCUPANCY</FormattedHTMLMessage>
                                        </h5>
                                        <p className="pt-2 text-muted">
                                            <FormattedHTMLMessage id="app.ServicesCardText3">Understand how your company uses the workplace and optimize space management.</FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center p-4 mt-3">
                                    <div className="service-icon">
                                        <i className="mdi-didy mdi-didy-knowledge-sharing text-custom-Pink"></i>
                                    </div>
                                    <div className="service-desc pt-4">
                                        <h5>
                                            <FormattedHTMLMessage id="app.ServicesCardTitle4Part1">KNOWLEDGE</FormattedHTMLMessage>
                                            <br></br>
                                            <FormattedHTMLMessage id="app.ServicesCardTitle4Part2">SHARING</FormattedHTMLMessage>
                                        </h5>
                                        <p className="pt-2 text-muted">
                                            <FormattedHTMLMessage id="app.ServicesCardText4">Learn from knowledge management, channels, speed & exchange.</FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mp-2">
                            <div className="col-md-3">
                                <div className="text-center p-4 mt-3">
                                    <div className="service-icon">
                                        <i className="mdi-didy mdi-didy-talent text-custom-Pink"></i>
                                    </div>
                                    <div className="service-desc pt-4">
                                        <h5>
                                            <FormattedHTMLMessage id="app.ServicesCardTitle5Part1">TALENT</FormattedHTMLMessage>
                                            <br></br>
                                            <FormattedHTMLMessage id="app.ServicesCardTitle5Part2">MANAGEMENT</FormattedHTMLMessage>
                                        </h5>
                                        <p className="pt-2 text-muted">
                                            <FormattedHTMLMessage id="app.ServicesCardText5">Attract, retain & awaken the talent of your organisation. Use metrics to unleash your human potential.</FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center p-4 mt-3">
                                    <div className="service-icon">
                                        <i className="mdi-didy mdi-didy-use-technology text-custom-Purple"></i>
                                    </div>
                                    <div className="service-desc pt-4">
                                        <h5>
                                            <FormattedHTMLMessage id="app.ServicesCardTitle6Part1">USE OF</FormattedHTMLMessage>
                                            <br></br>
                                            <FormattedHTMLMessage id="app.ServicesCardTitle6Part2">TECHNOLOGY</FormattedHTMLMessage>
                                        </h5>
                                        <p className="pt-2 text-muted">
                                            <FormattedHTMLMessage id="app.ServicesCardText6">Understand the digital maturity of your organization.</FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center p-4 mt-3">
                                    <div className="service-icon">
                                        <i className="mdi-didy mdi-didy-wellness text-custom-Green"></i>
                                    </div>
                                    <div className="service-desc pt-4">
                                        <h5>
                                            <FormattedHTMLMessage id="app.ServicesCardTitle7Part1">WELL-BEING AND</FormattedHTMLMessage>
                                            <br></br>
                                            <FormattedHTMLMessage id="app.ServicesCardTitle7Part2">COMFORT</FormattedHTMLMessage>
                                        </h5>
                                        <p className="pt-2 text-muted">
                                            <FormattedHTMLMessage id="app.ServicesCardText7">Look after the wellbeing of the people at your organisation and make sure of their comfort.</FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center p-4 mt-3">
                                    <div className="service-icon">
                                        <i className="mdi-didy mdi-didy-diversity text-custom-Orange"></i>
                                    </div>
                                    <div className="service-desc pt-4">
                                        <h5>
                                            <FormattedHTMLMessage id="app.ServicesCardTitle8Part1">DIVERSITY AND</FormattedHTMLMessage>
                                            <br></br>
                                            <FormattedHTMLMessage id="app.ServicesCardTitle8Part2">INTEGRATION</FormattedHTMLMessage>
                                        </h5>
                                        <p className="pt-2 text-muted">
                                            <FormattedHTMLMessage id="app.ServicesCardText8">Celebrate the diversity acknowledging the differences that make your company unique.</FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Aux>
        );
    }
}

export default Services;
