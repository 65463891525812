import React, { Component } from 'react';
import { connect } from 'react-redux';
import Aux from '../../hoc/Aux_';
import Header from '../Header/Header';
import HeaderLight from '../Header/HeaderLight';
import Footer from '../Footer/Footer';
import CookieBanner from '../UI/Legal/CookieBanner';
import Modal from 'react-modal';
import { MODAL_CLOSE } from '../../store/action';

Modal.setAppElement('#root');
class Layout extends Component {
    render() {
        return (
            <Aux>
                {this.props.loginpage ? this.props.children :
                    <main>
                        {this.props.islight ? <HeaderLight /> : <Header />}
                        {this.props.children}
                        <Footer />
                        <CookieBanner />
                        <Modal
                            isOpen={this.props.modalIsOpen}
                            onRequestClose={this.props.closeModal}
                            shouldCloseOnOverlayClick={true}
                        >
                            {this.props.modalContent}
                             <div class="modal-footer">
                                <input onClick={this.props.closeModal} type="button" className="submitBnt btn btn-custom" value="Close"/>
                             </div>
                        </Modal>
                    </main>
                }
            </Aux>
        );
    }
}

const mapStateToProps = state => ({
    modalIsOpen: state.modal.isOpen,
    modalContent: state.modal.content
});

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch({
        type: MODAL_CLOSE
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
