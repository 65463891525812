import React, { Component } from 'react';
import Aux from '../../../hoc/Aux_';
import { FormattedHTMLMessage } from 'react-intl';

class DigitalDynamics extends Component {
    render() {
        return (
            <Aux>
                <section className="section bg-light" id="digitaldynamics">
                    <div className="container mt-5">
                        <div className="row justify-content-center">
                            <div className="col-md-8 text-center">
                                <h2 className="font-weight-bold pt-5">
                                    <FormattedHTMLMessage id="app.digitaldynamicsMainHeader">Save costs, increase productivity and enhance human experience</FormattedHTMLMessage>
                                </h2>
                                <p className=" font-weight-light pt-1">
                                    <FormattedHTMLMessage id="app.digitaldynamicsParagraphPart1">Large companies invest important resources into initiatives that transform their organizations.</FormattedHTMLMessage>
                                </p>
                                <img src="images/DiDyColor.png" alt="" className="img-fluid center-block my-5" />                                
                            </div>
                        </div>
                        <div className="row vertical-content m-3">
                            <div className="col-md-5 p-6 mx-6">
                                <div className="features-box">
                                    <div className="features-icon">
                                        <section className="text-custom-Green float">
                                            <i className="mdi-didy mdi-didy-smart text-custom-Green">
                                            <span className="text-custom-size ml-2">
                                                <FormattedHTMLMessage id="app.digitaldynamicsMainTitle1">SMART ANALYTICS</FormattedHTMLMessage>
                                            </span>
                                            </i>
                                        </section>
                                    </div>
                                    <div className="features-desc">
                                        <h2>
                                            <FormattedHTMLMessage id="app.digitaldynamicsTitle1">Take advantage of existing data in your organization</FormattedHTMLMessage>
                                        </h2>
                                        <p className="pt-2">
                                            <FormattedHTMLMessage id="app.digitaldynamicsText1">Di-Dy breaks down barriers in your organization like the lack of knowledge and the necessary tools. We enable you to take advantage of useful data and to correlate numerous source that already exists.</FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 pt-4">
                                <img src="images/Recurso 4.svg" alt="" className="img-fluid img-custom rounded" />
                            </div>
                        </div>
                        <div className="row vertical-content m-3">
                            <div className="col-md-5 pt-4">
                                <img src="images/Recurso 1.svg" alt="" className="img-fluid img-custom rounded" />
                            </div>
                            <div className="col-md-5 p-6 mx-6">
                              <div className="features-box">
                                    <div className="features-icon">
                                        <i className="mdi-didy mdi-didy-seamless text-custom-Blue">
                                            <span className="text-custom-size ml-2">
                                                <FormattedHTMLMessage id="app.digitaldynamicsMainTitle2">SEAMLESS INFRAESTRUCTURE</FormattedHTMLMessage>
                                            </span>
                                        </i>
                                    </div>
                                    <div className="features-desc">
                                        <h2>
                                            <FormattedHTMLMessage id="app.digitaldynamicsTitle2">Anticipate to your business needs</FormattedHTMLMessage>
                                        </h2>

                                        <p className="font-weight-light pt-1">
                                            <FormattedHTMLMessage id="app.digitaldynamicsText2">Di-Dy provides real-time insights on the trends which drive your business and dives among data with a series of precise indicators and personalized reports. Your corporate objectives, such as productivity, agility, innovation, or the attraction/retention of talent will be considered in the automated data collection process.</FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row vertical-content m-3">
                            <div className="col-md-5 p-6 mx-6">
                              <div className="features-box">
                                    <div className="features-icon">
                                        <i className="mdi-didy mdi-didy-trend-evolution text-custom-Purple">
                                            <span className="text-custom-size ml-2">
                                                <FormattedHTMLMessage id="app.digitaldynamicsMainTitle3">TREND EVOLUTION</FormattedHTMLMessage>
                                            </span>
                                        </i>
                                    </div>
                                    <div className="features-desc">
                                        <h2>
                                            <FormattedHTMLMessage id="app.digitaldynamicsTitle3">Visualize the trends that drive your business</FormattedHTMLMessage>
                                        </h2>
                                        <p className="font-weight-light pt-1">
                                            <FormattedHTMLMessage id="app.digitaldynamicsText3">Manage your data through Di-Dy's dashboards and reports designed by experts in work and organizational transformation. Benefit from a machine learning engine and algorithms that help you to anticipate the future.</FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 pt-4">
                                <img src="images/Recurso 2.svg" alt="" className="img-fluid img-custom rounded" />
                            </div>
                        </div>
                        <div className="row vertical-content m-3">
                            <div className="col-md-5 pt-4">
                                <img src="images/Recurso 3.svg" alt="" className="img-fluid img-custom rounded" />
                            </div>
                            <div className="col-md-5 p-6 mx-6">
                              <div className="features-box">
                                    <div className="features-icon">
                                        <i className="mdi-didy mdi-didy-data-driven text-custom-Pink">
                                            <span className="text-custom-size ml-2">
                                                <FormattedHTMLMessage id="app.digitaldynamicsMainTitle4">DATA-DRIVEN CULTURE</FormattedHTMLMessage>
                                            </span>
                                        </i>
                                    </div>
                                    <div className="features-desc">
                                        <h2>
                                            <FormattedHTMLMessage id="app.digitaldynamicsTitle4">Take accurate decisions and develop Company Experience</FormattedHTMLMessage>
                                        </h2>
                                        <p className="font-weight-light pt-1">
                                            <FormattedHTMLMessage id="app.digitaldynamicsText4">Define and implement transformation initiatives with your own Di-Dy specialist to meet your needs and build a effective, efficient and innovative work environment. Evaluate your company and compare your results to similar organizations to get an overview of your competitive advantage.</FormattedHTMLMessage>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Aux>
        );
    }
}

export default DigitalDynamics;
